/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { checkoutKeyCookies, features } from '@config';
import { createHash } from '@core/helpers/encryption';
import { BLACK } from '@core/theme/colors';
import ErrorPopup from '@core_modules/theme/components/ErrorPopup';
import PopupInstallAppMobile from '@core_modules/theme/components/custom-install-popup/mobile';
import Copyright from '@core_modules/theme/components/footer/components/copyright';
import useStyles from '@core_modules/theme/layout/style';
import { breakPointsUp } from '@helper_theme';
import { useTranslation } from '@i18n';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';

const Message = dynamic(() => import('@common_toast'), { ssr: false });
const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });
const ScrollToTop = dynamic(() => import('@common_scrolltotop'), { ssr: false });

const offsetStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
}));

const Layout = (props) => {
    const bodyStyles = useStyles();
    const router = useRouter();
    const toolbarStyles = offsetStyles();
    const {
        pageConfig, children, isLogin, onlyCms, withLayoutFooter = true, useTopNav = false, DetailBarChildren,
    } = props;
    const { t } = useTranslation(['common', 'home', 'checkout']);
    const { schemaOrg = null, headerDesktop = true, footer = true } = pageConfig;
    const [state, setState] = useState({
        toastMessage: {
            open: false,
            variant: '',
            text: '',
        },
        backdropLoader: false,
        errorPopup: {
            open: false,
            label: t('common:error:popup:label'),
            description: t('common:error:popup:description'),
            showRefresh: true,
            showBackToStore: true,
        },
    });

    const refFooter = useRef(null);

    const handleSetToast = (message) => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                ...message,
            },
        });
    };

    const handleLoader = (status = false) => {
        setState({
            ...state,
            backdropLoader: status,
        });
    };

    const handleCloseMessage = () => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                open: false,
            },
        });
    };

    const showSessionExpired = () => {
        handleSetToast({
            open: true,
            variant: 'info',
            text: t('common:error:sessionExpired'),
        });
    };

    const handleCloseErrorPopup = () => {
        setState({
            ...state,
            errorPopup: {
                open: false,
                label: t('common:error:popup:label'),
                description: t('common:error:popup:description'),
                showRefresh: true,
                showBackToStore: true,
            },
        });
    };

    const handleOpenErrorPopup = (param = {}) => {
        setState({
            ...state,
            errorPopup: {
                ...state.errorPopup,
                ...param,
            },
        });
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.toastMessage = handleSetToast;
            window.errorPopup = handleOpenErrorPopup;
            window.backdropLoader = handleLoader;
            window.showSessionExpired = showSessionExpired;
            const custData = Cookies.getJSON(checkoutKeyCookies.config);
            const custPhoneNumber = Cookies.getJSON(checkoutKeyCookies.data);
            const tagManagerArgs = {
                dataLayer: {
                    pageName: pageConfig.title,
                    customerGroup: isLogin === 1 ? 'GENERAL' : 'NOT LOGGED IN',
                },
            };

            if (custData && custData.customerEmail) {
                tagManagerArgs.dataLayer.customerId = custData.customerEmail;
                tagManagerArgs.dataLayer.eid = createHash(custData.customerEmail);
            }

            if (custPhoneNumber?.phonenumber) {
                const checkPhonenumberPrefix = custPhoneNumber.phonenumber.slice(0, 1);

                if (checkPhonenumberPrefix === '0') {
                    custPhoneNumber.phonenumber = `+62${custPhoneNumber.phonenumber.slice(1)}`;
                } else {
                    custPhoneNumber.phonenumber = `+62${custPhoneNumber.phonenumber}`;
                }

                tagManagerArgs.dataLayer.pid = createHash(custPhoneNumber.phonenumber);
            }
            TagManager.dataLayer(tagManagerArgs);
        }
        // setMainMinimumHeight(refFooter.current.clientHeight + refHeader.current.clientHeight);
    }, []);

    const desktop = breakPointsUp('sm');

    // const styles = {
    //     marginBottom: pageConfig.bottomNav ? '60px' : 0,
    // };

    // if (!headerDesktop) {
    //     styles.marginTop = 0;
    // }

    const pageWithoutInstallButton = ['/', '/success', '/401', '/_error', '/confirmpayment'];

    const [displayed, setDisplayed] = useState(false);
    /*
    Top margins based on route, needs improvement.
    */
    const isNotOrderPage = router.pathname === '/dashboard' || router.pathname === '/dashboard/account';
    const isOrderDetailPage = router.pathname === '/dashboard/order/[id]';
    const isTrackOrderPage = router.pathname === '/track-order/[state]';
    const excludeInstallButton = pageWithoutInstallButton.indexOf(router.pathname) !== -1;
    const topNavMarginWithInstall = isNotOrderPage ? '64px' : isOrderDetailPage ? '120px' : '154px';
    const topNavMarginNoInstall = isNotOrderPage ? '0' : isTrackOrderPage ? '65px' : isOrderDetailPage ? '56px' : '90px';

    return (
        <>
            <Head>
                <meta name="keywords" content={pageConfig.title ? pageConfig.title : 'belanjaku.app'} />
                <meta name="robots" content="INDEX,FOLLOW" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <meta name="format-detection" content="telephone=no" />
                <title>{pageConfig.title ? pageConfig.title : 'Swift Pwa'}</title>
                {schemaOrg
                    ? schemaOrg.map((val, idx) => (
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(val) }} key={idx} />
                    ))
                    : null}
            </Head>

            {!excludeInstallButton && (
                <div className="container-menubar">
                    <AppBar className="container-app_bar" position="fixed">
                        {features.customInstallApp.enabled ? <PopupInstallAppMobile displayed={displayed} setDisplayed={setDisplayed} /> : null}
                        {useTopNav && <Toolbar className="container-navigator">{DetailBarChildren && <DetailBarChildren />}</Toolbar>}
                    </AppBar>
                </div>
            )}

            <main className={classNames(!onlyCms ? 'main-app' : 'main-app main-app-cms', toolbarStyles.offset)} id="maincontent">
                <Loading open={state.backdropLoader} />
                <Message
                    {...state.toastMessage}
                    open={state.toastMessage.open}
                    variant={state.toastMessage.variant}
                    setOpen={handleCloseMessage}
                    message={state.toastMessage.text}
                />
                <ErrorPopup {...state.errorPopup} open={state.errorPopup.open} handleClose={handleCloseErrorPopup} />
                {router.pathname === '/confirmpayment' && useTopNav && DetailBarChildren && <DetailBarChildren />}
                {children}
                {desktop ? <ScrollToTop {...props} /> : null}

                {withLayoutFooter && (
                    <footer className={bodyStyles.footerContainer} ref={refFooter}>
                        <Copyright />
                    </footer>
                )}
            </main>
            <style jsx>
                {`
                    .container-menubar {
                        margin-top: ${displayed ? topNavMarginWithInstall : topNavMarginNoInstall};
                    }
                    .container-menubar :global(.container-app_bar) {
                        color: ${BLACK};
                        max-width: 100%;
                        background-color: transparent;
                        box-shadow: none;
                    }
                    .container-menubar :global(.container-navigator) {
                        background-color: white;
                        width: 100%;
                        max-width: 580px;
                        align-self: center;
                        box-shadow: 0px 3px 6px 0px rgba(47, 102, 255, 0.16);
                        -webkit-box-shadow: 0px 3px 6px 0px rgba(47, 102, 255, 0.16);
                        -moz-box-shadow: 0px 3px 6px 0px rgba(47, 102, 255, 0.16);
                    }
                `}
            </style>
        </>
    );
};

export default Layout;
