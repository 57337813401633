import { checkoutKeyCookies } from '@config';
import { resetData } from '@core/helpers/checkout';
import CustomTypography from '@core_modules/commons/Typography';
import { useTranslation } from '@i18n';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie';
import React from 'react';

const ErrorPopup = ({
    open,
    handleClose,
    label,
    description,
    showRefresh = true,
    showBackToStore = true,
}) => {
    const { t } = useTranslation('common');
    const config = Cookies.getJSON(checkoutKeyCookies.config);
    const backToStore = () => {
        const {
            hostname, ecpType, ecpErrorCallbackUrl, useCustomRedirectBack, customRedirectBackUrl,
        } = config;
        if (hostname) {
            if (useCustomRedirectBack && customRedirectBackUrl) {
                resetData();
                setTimeout(() => {
                    window.backdropLoader(false);
                    window.location.replace(customRedirectBackUrl);
                }, 1000);
            } else if (ecpType && (ecpType === 'shopify' || ecpType === 'belanjaku')) {
                setTimeout(() => {
                    resetData();
                    setTimeout(() => {
                        window.backdropLoader(false);
                        window.location.replace(ecpErrorCallbackUrl || `${hostname.replace('graphql', '')}`);
                    }, 1000);
                }, 1000);
            } else {
                resetData();
                setTimeout(() => {
                    window.backdropLoader(false);
                    window.location.replace(ecpErrorCallbackUrl || hostname.replace('graphql', ''));
                }, 1000);
            }
        }
    };

    const handleRefresh = () => {
        handleClose();
        window.location.reload();
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-desc"
            maxWidth="xs"
            disableEscapeKeyDown
            disableBackdropClick
        >
            <DialogTitle id="error-dialog-title">{label || t('common:error:popup:label')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-desc">
                    <CustomTypography variant="title">
                        {description || t('common:error:popup:description')}
                    </CustomTypography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    showRefresh && (
                        <Button onClick={handleRefresh} color="primary">
                            <CustomTypography type="bold" variant="title">
                                {t('common:error:popup:refresh')}
                            </CustomTypography>
                        </Button>
                    )
                }
                {
                    showBackToStore && (
                        <Button onClick={backToStore} color="primary" autoFocus>
                            <CustomTypography type="bold" variant="title">
                                {t('common:error:popup:backToStore')}
                            </CustomTypography>
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    );
};

export default ErrorPopup;
